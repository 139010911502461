@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  --color-primary: #f8673a;
  --color-secondary: #7c6853;
  --color-tertiary: #342f29;
  --color-white: #fff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


body {
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.container {
  margin: 0 auto;
  padding: 0 50px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--color-white);
  background-color: var(--color-primary);
  border: none;
  cursor: pointer;
}

button:hover {
  opacity: 0.9;
}

.heading-primary {
  color: var(--color-white);
  font-size: 60px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.1;
  padding-bottom: 10px;
}

.heading-primary span {
  font-weight: 700;
}

.special-word {
  color: var(--color-primary);
  font-weight: 700;
}

.text-white {
  color: var(--color-white);
}

@media screen and (max-width: 768px){
  .container {
    padding: 0 20px;
  }

  .heading-primary {
    font-size: 45px;
  }
}

.heading-secondary {
  color: var(--color-tertiary);
  font-size: 60px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: 1.1;
  padding-bottom: 10px;
}