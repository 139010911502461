.gallery {
    display: grid;
    grid-template-rows: repeat(2, 15vw);
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5px;
}

.gallery_img{
    width: 100%;
    height: 100%;
}

.gallery_item-1{
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
}

.gallery_item-2{
    grid-row: 1 / span 2;
    grid-column: 2 / span 1;
}

.gallery_item-3{
    grid-row: 1 / span 2;
    grid-column: 3 / span 2;
}

.gallery_item-4{
    grid-row: 1 / span 1;
    grid-column: 5 / span -1;
}

.gallery_item-5{
    grid-row: 2 / span 1;
    grid-column: 1 / span 1;
}

.gallery_item-6{
    grid-row: 2 / span 1;
    grid-column: 5 / span -1;
}