.hero-img {
    height: 100vh;
    background-image: url('../../../assets/bar.jpg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    position: relative;
    width: auto;
}

.hero-content {
    position: absolute;
    top: 40%;
}

.hero-content p {
    font-size: 20px;
}